<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="minHeight">
          <div class="row justify-content-between align-items-center">
            <div class="col-12 col-md-8 col-lg-9 d-flex">
              <base-input
                type="search"
                v-model="searchedCustomer"
                @input="searchCustomer()"
                :placeholder="$t('searchCustomer')"   
                class="mb-4 w-100"             
              >
              </base-input>
              <base-button simple type="primary" class="clean-button" @click="cleanSearch()">
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
            <div class="col-12 col-md-auto ">
              <Popover
                  trigger="manual"
                  placement="left"
                  :content="$t('clickAddCustomer')"
                  popper-class="font-weight-bold shadow-popover"
                  v-model="isPopoverVisible"
              >
                <base-button
                    slot="reference"
                    v-if="isManager || isEmployee"
                    type="primary"
                    class="mb-4 mr-auto"
                    @click="$router.push({ name: 'newCustomer' })"
                >{{ $tc("new") }}</base-button
                >
              </Popover>
            </div>
          </div>
          <base-table
              v-if="!isSpinnerShow"
              :data="customers"
              :columns="customersColumns"
              @clickRow="goToDetailView"
              class="col table-responsive-sm"
          >
            <template slot="columns">
              <th scope="col-auto">#</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
              <th scope="col">{{ $t("phone") }}</th>
              <th scope="col">{{ $t("selfRegister") }}</th>
              <th scope="col">{{ $t("registerDate") }}</th>
            </template>
            <template slot-scope="{ row }">
              <td class="col-auto">
                <div class="photo mx-0">
                  <avatar
                      :src="row.picture"
                      @error="replaceByDefault"
                      :username="row.name"
                      :size="30"
                  ></avatar>
                </div>
              </td>
              <td class="col-4">{{ row.name }}</td>
              <td class="col-4">{{ row.email }}</td>
              <td class="col-2">{{ row.phone }}</td>
              <td class="col-1">
                <div v-if="row.type === 2" class="photo mx-0">
                  <img
                    src="@/assets/img/Logo_AS_Transparent.png"
                    :alt="$t('alt.ASUser')"
                    width="100%"
                    height="100%"
                  />
                </div>
              </td>
                  <td class="col-4">{{ row.created_at }}</td>
            </template>
          </base-table>
          <base-table
              v-else-if="customers.length === 0"
              :data="[1]"
              class="table-responsive-md"
          >
            <template slot="columns">
              <th scope="col-auto">#</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
              <th scope="col">{{ $t("phone") }}</th>
                            <th scope="col">{{ $t("selfRegister") }}</th>
              <th scope="col">{{ $t("registerDate") }}</th>
            </template>
            <template>
              <td class="col-auto"></td>
              <td class="col-4"></td>
              <td class="col-4"><Spinner parentClasses="justify-content-start"></Spinner></td>
              <td class="col-2"></td>
              <td class="col-1"></td>
              <td class="col-4"></td>
            </template>
          </base-table>
          <template slot="footer">
            <pagination
                v-if="customers.length > 0"
                @turnPage="getItems"
                element="customer"
                :perPage="pagination.perPage"
                :lastPage="pagination.lastPage"
                :totalItems="pagination.totalItems"
                :isChanging="pagination.isChanging"
                :currentPage="currentPage"
            ></pagination>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Pagination,
  BaseTable,
  BaseButton,
  BaseInput,
  Spinner,
} from "@/components/index";
import Avatar from "vue-avatar";
import { mapState } from "vuex";
import { Popover } from "element-ui";
import defaultImage from "../../assets/img/img-default.png";



export default {
  name: "customerList",
  data() {
    return {
      route: "/customers",
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
        isChanging: false
      },
      customers: [],
      customersColumns: ["picture", "name", "email", "phone", "type", "created_at"],
      isSpinnerShow: true,
      isPopoverVisible: false,
      searchedCustomer: '',
      search: null,
      currentPage: 1
    };
  },
  computed: {
    ...mapState(["isManager", "isEmployee", "defaultTitle"]),
  },
  methods: {
    async getItems(page = 1) {
      this.pagination.isChanging = true;
      this.currentPage = page;
      const route =  this.searchedCustomer? `${this.route}?query=${this.searchedCustomer}&page=${this.currentPage}` : `${this.route}?page=${this.currentPage}`;
      
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.customers;
        this.customers = data.data;
        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        this.isSpinnerShow = false;
        this.isPopoverVisible = this.customers.length === 0;
      }
      this.pagination.isChanging = false;
    },
    goToDetailView(itemId) {
      this.$router.push({
        name: "customersManagement",
        params: { id: itemId },
      });
    },
    async searchCustomer(){
      if(this.search) {
        clearTimeout( this.search)
        this.search = null
      }
      if(this.searchedCustomer.length > 0){
        this.pagination.isChanging = true;
        
        this.search = setTimeout( async()=>{
          this.currentPage = 1;
           this.getItems()
        },1000)
      }
      
      if(this.searchedCustomer.length === 0){
        this.searchedCustomer = null;
        this.getItems()
      }
    },
    cleanSearch(){
      if(this.searchedCustomer !== null){
        this.searchedCustomer = null;
        this.getItems();
      }
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
  },
  mounted() {
    this.getItems();
  },
  metaInfo() {
    return { title: `${this.$tc('customer', 0)} - ${this.defaultTitle}` }
  },
  components: {
    Card,
    Pagination,
    Avatar,
    BaseTable,
    BaseButton,
    BaseInput,
    Spinner,
    Popover
  },
};
</script>

<style scoped >
.minHeight {
  min-height: 90vh !important;
}
.shadow-popover {
  box-shadow: 0 2px 12px 0 rgb(0, 0, 0, 50%);
}
.clean-button{
  height: 38px;
  width: 38px;
  margin: 0;
  margin-left:5px;
  padding: 0;

}
</style>
